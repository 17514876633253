import { Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.routes').then((m) => m.routes),
    // Trigger login at this route
    canActivate: [AuthGuard]
  },
//  {
//    path: 'callback',
//    loadComponent: () => import('./features/callback/callback.component').then(mod => mod.CallbackComponent)
//  },
];
